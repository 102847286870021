import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import { Rate, message, Modal } from 'antd';
import TrangChu from '../page/trang-chu/index'
import { IoMenu } from "react-icons/io5";
import api from '../api';

const PageHome = () => {

    const [messageApi, contextHolder] = message.useMessage();
    const ref = useRef(null);
    const [width, setWidth] = useState();
    const [menu, setMenu] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [statusName, setStatusName] = useState(false);
    const [statusEmail, setStatusEmail] = useState(0);
    const [statusContent, setStatusContent] = useState(false);
    const [packageSelect, setPackageSelect] = useState({ Money: 0 });
    const [lockSelect, setLockSelect] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [dataContact, setDataContact] = useState({
        "Name": "",
        "Email": "",
        "Title": "",
        "Content": ""
    })

    const packageUse = [
        {
            ID: 1,
            Name: 'Phí khởi tạo',
            Money: 500000,
            Discount: 1000000,
            MoneyLock: 0
        },
        {
            ID: 2,
            Name: 'Gói 1 năm (0 khóa)',
            Money: 100000,
            Discount: 85000,
            MoneyLock: 0
        },
        {
            ID: 3,
            Name: 'Gói 1 năm /1 khóa',
            Money: 100000,
            Discount: 85000,
            MoneyLock: 40000
        },
        {
            ID: 4,
            Name: 'Gói vĩnh viễn (không khóa)',
            Money: 400000,
            Discount: 340000,
            MoneyLock: 0
        },
        {
            ID: 5,
            Name: 'Gói vĩnh viễn/1 khóa',
            Money: 400000,
            Discount: 340000,
            MoneyLock: 160000
        },
        {
            ID: 6,
            Name: 'Phí duy trì (free 2 năm đầu)/1 năm',
            Money: 500000,
            Discount: 500000,
            MoneyLock: 0
        }
    ]

    const changeMenu = () => {
        setMenu(!menu)
    }

    const changeHoten = (e) => {
        if (e.target.value.length !== 0) { setStatusName(false) }
        setDataContact({ ...dataContact, "Name": e.target.value })
    }

    const changeEmail = (e) => {
        if (e.target.value.length !== 0) { setStatusEmail(0) }
        setDataContact({ ...dataContact, "Email": e.target.value })
    }

    const changeTitle = (e) => {
        setDataContact({ ...dataContact, "Title": e.target.value })
    }

    const changeContent = (e) => {
        if (e.target.value.length !== 0) { setStatusContent(false) }
        setDataContact({ ...dataContact, "Content": e.target.value })
    }

    const submitContact = async () => {
        var regex = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/
        if (dataContact.Name.length === 0) { setStatusName(true) }
        if (dataContact.Email.length === 0) { setStatusEmail(1) }
        else if (regex.test(dataContact.Email) === false) { setStatusEmail(2) }
        if (dataContact.Content.length === 0) { setStatusContent(true) }
        if (statusName === false && statusEmail === 0 && statusContent === false) {
            try {
                const response = await api.post('/frontend/contact', dataContact);
                if (response.success === true) {
                    setDataContact({
                        "Name": "",
                        "Email": "",
                        "Title": "",
                        "Content": ""
                    })
                    messageApi.open({
                        type: 'success',
                        content: 'Đã gửi thành công',
                    });
                } else {
                    messageApi.open({
                        type: 'error',
                        content: 'Gửi không thành công',
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const selectPackage = (e) => {
        var temp = packageUse.find(item => Number(e.target.value) === item.ID)
        temp ? setPackageSelect(temp) : setPackageSelect({ Money: 0, Name: '' })
    }

    const changeNumberRoom = (e) => {
        setLockSelect(Number(e.target.value))
    }

    const openPriceList = () => {
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
    };

    const settingsTrademark = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 7,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            }
        ]
    };

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
    }, []);

    useEffect(() => {
        var keyMoney = 0
        var initiationFee = 0
        if (lockSelect < 20) {
            keyMoney = (packageSelect.Money + packageSelect.MoneyLock) * lockSelect;
            initiationFee =  packageUse?.find(item => item.ID === 1).Money;
        } else {
            keyMoney = (packageSelect.Discount + packageSelect.MoneyLock) * lockSelect
            initiationFee =  packageUse?.find(item => item.ID === 1).Discount;
        }

        if( isNaN(keyMoney) || lockSelect===0 ) {
            setTotalPrice(0)
        } else {
            setTotalPrice(initiationFee + keyMoney)
        }
    }, [lockSelect, packageSelect])

    return (
        <div className='over' ref={ref}>
            {contextHolder}
            <div className='over over__background--header'>

                <div className = 'zalo_floating'>
                  <a href="https://zalo.me/0934459099" >
                    <img src={require('../assets/img/ic_zalo.png')} alt="zalo link" className='zalo_floating-img' />
                  </a>
                </div>
                <div className='container'>
                    <div className='header__menu'>
                        <div className='header__menu-logo'>
                          <img loading="lazy" src={require('../assets/img/icon.png')} alt="Logo" className='header__logo' />
                          <div className = 'header__menu-info'>
                            <p><b>LFHOTEL - HỆ THỐNG QUẢN TRỊ KHÁCH SẠN</b></p>
                            <p>Gọi cho chúng tôi: <a href="tel:+842462912111">024 6291 2111</a></p>
                          </div>
                        </div>
                        {
                            width < 550 ?
                                <div>
                                    <IoMenu style={{ fontSize: "30px" }} onClick={changeMenu} />
                                    <div className='header__menu--listMobi' style={{ display: menu ? "flex" : "none" }}>
                                        <a className='header__menu--item' href='#trangchu' >TRANG CHỦ</a>
                                        <a className='header__menu--item' href='#giaiphap' >GIẢI PHÁP</a>
                                        <a className='header__menu--item' href='#tinhnang' >TÍNH NĂNG</a>
                                        <a className='header__menu--item' href='#danhgia' >ĐÁNH GIÁ</a>
                                        <a className='header__menu--item' href='#banggia' >BẢNG GIÁ</a>
                                        <a className='header__menu--item' href='#lienhe' >LIÊN HỆ</a>
                                    </div>
                                </div>
                                :
                                <div className='header__menu--list'>
                                    <a className='header__menu--item' href='#trangchu' >TRANG CHỦ</a>
                                    <a className='header__menu--item' href='#giaiphap' >GIẢI PHÁP</a>
                                    <a className='header__menu--item' href='#tinhnang' >TÍNH NĂNG</a>
                                    <a className='header__menu--item' href='#danhgia' >ĐÁNH GIÁ</a>
                                    <a className='header__menu--item' href='#banggia' >BẢNG GIÁ</a>
                                    <a className='header__menu--item' href='#lienhe' >LIÊN HỆ</a>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <TrangChu />
            <div className='over' id='giaiphap'>
                <div className='container'>
                    <div className='solution__header'>
                        <div className='title'>Công nghệ hiện đại, cho phép quản lý phòng và nguồn điện qua thẻ từ và khóa thông minh</div>
                        <div className='description'>Dữ liệu đồng bộ, hợp nhất việc đặt chỗ, thanh toán, quản lý dọn phòng, thiết lập giá phòng...trên một nền tảng duy nhất.</div>
                    </div>
                    <div className='solution__show'>
                        <div className='solution__show--item'>
                            <img loading="lazy" src={require('../assets/img/solution1.png')} alt="solution 1" className='solution__img' />
                            <div className='solution__item--title'>Giao diện được thiết kế thông minh, dễ sử dụng</div>
                            <div className='solutin__item--description'>Phù hợp với mọi kích thước màn hình (máy tính, máy tính bảng, điện thoại di động).</div>
                        </div>
                        <div className='solution__show--item'>
                            <img loading="lazy" src={require('../assets/img/solution2.png')} alt="solution 2" className='solution__img' />
                            <div className='solution__item--title'>ĐỘ BẢO MẬT CAO</div>
                            <div className='solutin__item--description'>QUẢN LÝ HỆ THỐNG KHÁCH SẠN VỚI MỨC AN TOÀN CỰC CAO, PHÒNG TRÁNH CÁC RỦI RO AN NINH MẠNG</div>
                        </div>
                        <div className='solution__show--item'>
                            <img loading="lazy" src={require('../assets/img/solution3.png')} alt="solution 3" className='solution__img' />
                            <div className='solution__item--title'>Tích hợp với hầu hết các loại khóa từ</div>
                            <div className='solutin__item--description'>VẬN HÀNH TỐT, KHẮC PHỤC TRIỆT ĐỂ CÁC NHƯỢC ĐIỂM CỦA CÁC LOẠI KHÓA TỪ ĐANG CÓ MẶT TRÊN THỊ TRƯỜNG</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='over over--background' id='tinhnang'>
                <div className='container'>
                    <div className='displayFlex info__view'>
                        <img loading="lazy" src={require('../assets/img/MacBookPro.png')} alt='macbook' className='img--macbook' />
                        <div className='info__view--description'>
                            <div >
                                <div className='title textLeft'>KHAI THÁC TỐI ĐA KHẢ NĂNG QUẢN LÝ</div>
                                <div className='description textLeft'>VỚI THIẾT KẾ ĐƠN GIẢN HIỆU QUẢ, HỆ THỐNG TÍCH HỢP CÁC CÔNG CỤ GIÚP NGƯỜI DÙNG VẬN HÀNH TRƠN TRU</div>
                            </div>
                            <a className='button button__exploit' href='https://hotel.liforte.com/fnb' target='_blank' rel='noreferrer' >DÙNG THỬ</a>
                        </div>
                    </div>
                    <div className='displayFlex info__view'>
                        <div className='info__view--description' >
                            <div >
                                <div className='title textLeft'>MỨC ĐỘ AN TOÀN CAO, PHÙ HỢP MỌI CẤU HÌNH</div>
                                <div className='description textLeft'>MỌI THÔNG TIN ĐƯỢC MÃ HÓA VÀ LƯU TRỮ TRÊN ĐIỆN TOÁN ĐÁM MÂY ĐỂ LOẠI BỎ HOÀN TOÀN MỐI LO NGẠI VỀ AN NINH - RỦI RO AN TOÀN.
                                    HỆ THỐNG VẬN HÀNH TRÊN NỀN TẢNG ONLINE VÀ KHÔNG ĐÒI HỎI CẤU HÌNH PHỨC TẠP</div>
                            </div>
                            <a className='button button__exploit' href='https://hotel.liforte.com/fnb' target='_blank' rel='noreferrer' >DÙNG THỬ</a>
                        </div>
                        <img loading="lazy" src={require('../assets/img/MacBookPro.png')} alt='macbook' className='img--macbook' />
                    </div>
                </div>
            </div>
            <div className='over' id='danhgia'>
                <div className='container'>
                    <div className='slider__view'>
                        <Slider {...settings}>
                            <div className='slider__item'>
                                <img loading="lazy" src={require('../assets/img/avatar.jpg')} alt='macbook' className='slider__avatar' />
                                <div className='slider__comment'>Hệ thống quản lý khách sạn là một công cụ rất hữu ích được rất nhiều các khách sạn hiện đại triển khai</div>
                                <div className='slider__name'>Mr. Nguyễn Mạnh Chung</div>
                                <div className='slider__name'>Chủ Tịch HĐQT</div>
                                <Rate disabled allowHalf defaultValue={5} className='slider__rate' />
                            </div>
                            <div className='slider__item'>
                                <img loading="lazy" src={require('../assets/img/avatar2.jpg')} alt='macbook' className='slider__avatar' />
                                <div className='slider__comment'>Hệ thống quản lý khách sạn chuyên nghiệp không những giúp chủ sở hữu quản lý, điều hành mọi hoạt động kinh doanh và công việc của nhân viên mà còn góp phần to lớn vào quá trình hoạch định mục tiêu và triển khai các chiến lược kinh doanh</div>
                                <div className='slider__name'>Ms. Lê Lan Anh</div>
                                <div className='slider__name'>Phó giám đốc Chi Nhánh</div>
                                <Rate disabled allowHalf defaultValue={4.5} className='slider__rate' />
                            </div>
                            <div className='slider__item'>
                                <img loading="lazy" src={require('../assets/img/avatar3.jpg')} alt='macbook' className='slider__avatar' />
                                <div className='slider__comment'>Phần mềm quản lý khách sạn là phần mềm quản lý khách sạn tốt nhất, chuyên nghiệp nhất giúp cho việc quản lý khách sạn của bạn trở nên đơn giản, dễ dàng.</div>
                                <div className='slider__name'>Mr. Nguyễn Khắc Mạnh</div>
                                <div className='slider__name'>Tổng giám đốc</div>
                                <Rate disabled allowHalf defaultValue={5} className='slider__rate' />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
            <div className='over over--background'>
                <div className='container'>
                    <div className='div--center' style={{ padding: '110px 0 60px 0' }}>
                        <img loading="lazy" src={require('../assets/img/MacBookPro.png')} alt='macbook' className='img--macbook' />
                    </div>
                    <div className='solution__show'>
                        <div className='solution__show--item'>
                            <img loading="lazy" src={require('../assets/img/solution1.png')} alt="solution 1" className='solution__img' />
                            <div className='solution__item--title'>Dễ sử dụng, thao tác nhanh</div>
                            <div className='solutin__item--description'>Quản lý đặt/trả phòng trực quan qua sơ đồ phòng. Tối ưu hóa thời gian xử lý nhận/trả phòng chỉ bằng thao tác quẹt thẻ đơn giản.</div>
                        </div>
                        <div className='solution__show--item'>
                            <img loading="lazy" src={require('../assets/img/solution2.png')} alt="solution 2" className='solution__img' />
                            <div className='solution__item--title'>Tối ưu hóa doanh thu</div>
                            <div className='solutin__item--description'>Tránh thất thoát về báo cáo doanh số.<br />
                                Tránh lãng phí nguồn điện. <br />
                                Tránh sai lệch trong tính phí phòng</div>
                        </div>
                        <div className='solution__show--item'>
                            <img loading="lazy" src={require('../assets/img/solution3.png')} alt="solution 3" className='solution__img' />
                            <div className='solution__item--title'>Hệ thống báo cáo thống kê đa dạng, trực quan</div>
                            <div className='solutin__item--description'>Báo cáo doanh thu, lợi nhuận. Báo cáo đặt phòng, nhận phòng, trả phòng… Báo cáo và thống kê hóa đơn, chứng từ. Báo cáo xuất – nhập – tồn. Thống kê danh sách lưu trú</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='over'>
                <div className='container'>
                    <div className='displayFlex info__over' >
                        <div className='info__item'>
                            <img loading="lazy" src={require('../assets/img/info1.png')} alt="solution 2" className='info__img' />
                            <div className='info--title' >14K+</div>
                            <div className='info--description' >KHÁCH HÀNG ĐANG SỬ DỤNG</div>
                        </div>
                        <div className='info__item'>
                            <img loading="lazy" src={require('../assets/img/info2.png')} alt="solution 2" className='info__img' />
                            <div className='info--title' >1K+</div>
                            <div className='info--description' >KHÁCH HÀNG LỰA CHỌN GÓI CAO HƠN</div>
                        </div>
                        <div className='info__item'>
                            <img loading="lazy" src={require('../assets/img/info3.png')} alt="solution 2" className='info__img' />
                            <div className='info--title' >1K+</div>
                            <div className='info--description' >THIẾT BỊ DI ĐỘNG ĐƯỢC TÍCH HỢP</div>
                        </div>
                        <div className='info__item'>
                            <img loading="lazy" src={require('../assets/img/info4.png')} alt="solution 2" className='info__img' />
                            <div className='info--title' >14K+</div>
                            <div className='info--description' >DOANH NGHIỆP</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='over over--background'>
                <div className='container'>
                    <div className='info__phone'>
                        <div className='title' style={{ textAlign: "left" }}>NỀN TẢNG QUẢN TRỊ KHÁCH SẠN TOÀN DIỆN</div>
                        <div className='description' style={{ textAlign: 'left' }} >HỆ THỐNG QUẢN TRỊ KHÁCH SẠN ĐƯỢC THIẾT KẾ ĐÁP ỨNG TỐT CÁC YÊU CẦU CHO TẤT CẢ NHU CẦU QUY MÔ TỪ NHỎ - NHỎ VỪA - VỪA - LỚN</div>
                        <div className='description' style={{ textAlign: 'left', paddingBottom: '30px' }} >TĂNG 23% DOANH THU CHO KHÁCH SẠN KHI TỐI ƯU, RÚT GIẢM CÁC YẾU TỐ RƯỜM RÀ GÂY LÃNG PHÍ THỜI GIAN</div>
                        <a className='button' href='https://hotel.liforte.com/fnb' target='_blank' rel='noreferrer' >DÙNG THỬ</a>
                    </div>
                </div>
            </div>

            <div className='over'>
                <div className='container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className='title package__title--header' >NỀN TẢNG QUẢN TRỊ KHÁCH SẠN TOÀN DIỆN</div>
                    <div className='displayFlex package__over' >
                        <div className='package__view'>
                            <img loading="lazy" src={require('../assets/img/solution1.png')} alt="solution 1" className='solution__img' />
                            <div className='package__title'>GÓI QUẢN TRỊ CƠ BẢN</div>
                            <div className='package__title'>200,000 VND / KHÓA / 01 NĂM</div>
                            <div className='package__description'>QUẢN LÝ LỄ TÂN, DỊCH VỤ GTGT</div>
                            <div className='package__description'>KẾ TOÁN NỘI BỘ</div>
                            <a className='button' href='https://hotel.liforte.com/fnb' target='_blank' rel='noreferrer' >DÙNG THỬ</a>
                        </div>
                        <div className='package__view'>
                            <img loading="lazy" src={require('../assets/img/solution1.png')} alt="solution 1" className='solution__img' />
                            <div className='package__title'>GÓI QUẢN TRỊ NÂNG CAO</div>
                            <div className='package__title'>500,000 VND / KHÓA / 01 NĂM</div>
                            <div className='package__description'>QUẢN LÝ LỄ TÂN<br />QUẢN LÝ DỊCH VỤ GTGT<br />THỐNG KÊ BIỂU BẢNG</div>
                            <div className='package__description'>KẾ TOÁN NỘI BỘ<br />BẢNG KIỂM SOÁT THU CHI</div>
                            <a className='button' href='https://hotel.liforte.com/fnb' target='_blank' rel='noreferrer' >DÙNG THỬ</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='over over--background' style={{display:'none'}}>
                <div className='container'>
                    <div className='list__trademark'>
                        <Slider {...settingsTrademark}>
                            <img loading="lazy" src={require('../assets/img/info4.png')} alt="solution 2" className='info__img' />
                            <img loading="lazy" src={require('../assets/img/info4.png')} alt="solution 2" className='info__img' />
                            <img loading="lazy" src={require('../assets/img/info4.png')} alt="solution 2" className='info__img' />
                            <img loading="lazy" src={require('../assets/img/info4.png')} alt="solution 2" className='info__img' />
                            <img loading="lazy" src={require('../assets/img/info4.png')} alt="solution 2" className='info__img' />
                            <img loading="lazy" src={require('../assets/img/info4.png')} alt="solution 2" className='info__img' />
                            <img loading="lazy" src={require('../assets/img/info4.png')} alt="solution 2" className='info__img' />
                            <img loading="lazy" src={require('../assets/img/info4.png')} alt="solution 2" className='info__img' />
                            <img loading="lazy" src={require('../assets/img/info4.png')} alt="solution 2" className='info__img' />
                            <img loading="lazy" src={require('../assets/img/info4.png')} alt="solution 2" className='info__img' />
                        </Slider>
                    </div>
                </div>
            </div>
            <div className='over' id='banggia'>
                <div className='container' style={{ paddingTop: '120px' }}>
                    <div className='contact__over'>
                        <div className='contact__header'> THAM KHẢO GIÁ CÁC GÓI HỆ THỐNG </div>
                        <div className='contact__content'>
                            <div className="contact__content--left">
                                <p className="contact__content--title" >Thông tin liên hệ</p>
                                <p className="contact__content--des" >Công ty CP LiForte Việt Nam</p>
                                <p className="contact__content--des" >Địa chỉ: Số 4, 44/460 đường Khương Đình, phường Hạ Đình, quận Thanh Xuân, Hà Nội </p>
                                <p className="contact__content--des" >Gọi cho chúng tôi: 024 6291 2111</p>
                                <p className="contact__content--des" >Làm việc từ thứ Hai tới thứ Sáu</p>
                                <p className="contact__content--des" >08.00 - 17.00</p>
                                <div className='contact__view--icon' >
                                    <a className="contact__icon" href='/#'>
                                        <img src={require('../assets/img/iconFB.png')} alt='iconFB' />
                                    </a>
                                    <a className="contact__icon" href='/#'>
                                        <img src={require('../assets/img/iconZalo.png')} alt='iconZalo' />
                                    </a>
                                    <a className="contact__icon" href='/#'>
                                        <img src={require('../assets/img/iconSky.png')} alt='iconSky' />
                                    </a>
                                    <a className="contact__icon" href='/#'>
                                        <img src={require('../assets/img/iconTele.png')} alt='iconTele' />
                                    </a>
                                </div>
                            </div>
                            <div className="contact__content--right" >
                                <p className="contact__content--title" >THÔNG TIN KHÁCH SẠN BẠN MUỐN THAM KHẢO GIÁ</p>
                                <select className='select' onChange={selectPackage}>
                                    <option value='' >-- Gói sử dụng --</option>
                                    {
                                        packageUse.map((item) => {
                                            if(item.ID !== 1 && item.ID !== 6) {
                                                return (<option key={item.ID} value={item.ID} >{item.Name}</option>)
                                            }
                                            return null;
                                        })
                                    }
                                </select>
                                <input className='input--hotel' placeholder='Nhập số lượng phòng' onChange={changeNumberRoom} />
                                <p className='price__title' >CHI PHÍ DỰ KIẾN CHO KHÁCH SẠN CỦA BẠN:</p>
                                <p className='price__des' >
                                    Sử dụng <span className='price__bold'>{packageSelect?.Name} </span>
                                    cho số lượng <span className='price__bold'>{lockSelect} </span>
                                    phòng: <span className='price__bold'>{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> VNĐ
                                </p>
                                <p className='price__des' >QUÝ KHÁCH VUI LÒNG LIÊN HỆ ĐỂ BIẾT THÊM CHI TIẾT VÀ ĐƯỢC CHIẾT KHẤU GIÁ TỐT HƠN</p>
                                <div onClick={openPriceList} className='button'>Tham khảo giá</div>
                                <Modal title="Bảng giá dịch vụ" open={isModalOpen} onCancel={handleCancel}
                                    footer={[
                                        <div className='footer__custom'>
                                            <div key='back' onClick={handleCancel} className='button button__price--exit'> Quay lại </div>,
                                            <a key='link' className='button' href='https://hotel.liforte.com/fnb' target='_blank' rel='noreferrer' >DÙNG THỬ</a>
                                        </div>
                                    ]}
                                >
                                    <p className='price__table--title'>Giá phòng theo phí dịch vụ</p>
                                    <table style={{ width: '100%' }} >
                                        <tr>
                                            <th>Chi phí theo gói</th>
                                            <th>SL phòng &#60; 20</th>
                                            <th>SL phòng &#62; 20</th>
                                        </tr>
                                        {
                                            packageUse?.map((item) => {
                                                return (
                                                    <tr key={item.ID}>
                                                        <th>{item.Name}</th>
                                                        <th>{(item.Money + item.MoneyLock).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} VNĐ</th>
                                                        <th>{(item.Discount + item.MoneyLock).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} VNĐ</th>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </table>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='over' id='lienhe'>
                <div className='container' style={{ paddingTop: '120px' }}>
                    <div className='contact__over'>
                        <div className='contact__header'> Liên hệ với chúng tôi </div>
                        <div className='contact__content'>
                            <div className="contact__content--left">
                                <p className="contact__content--title" >Thông tin liên hệ</p>
                                <p className="contact__content--des" >Công ty CP LiForte Việt Nam</p>
                                <p className="contact__content--des" >Địa chỉ: Số 4, 44/460 đường Khương Đình, phường Hạ Đình, quận Thanh Xuân, Hà Nội</p>
                                <p className="contact__content--des" >Gọi cho chúng tôi: 024 6291 2111</p>
                                <p className="contact__content--des" >Làm việc từ thứ Hai tới thứ Sáu</p>
                                <p className="contact__content--des" >08.00 - 17.00</p>
                                <div className='contact__view--icon' >
                                    <a className="contact__icon" href='/#'>
                                        <img src={require('../assets/img/iconFB.png')} alt='iconFB' />
                                    </a>
                                    <a className="contact__icon" href='/#'>
                                        <img src={require('../assets/img/iconZalo.png')} alt='iconZalo' />
                                    </a>
                                    <a className="contact__icon" href='/#'>
                                        <img src={require('../assets/img/iconSky.png')} alt='iconSky' />
                                    </a>
                                    <a className="contact__icon" href='/#'>
                                        <img src={require('../assets/img/iconTele.png')} alt='iconTele' />
                                    </a>
                                </div>
                            </div>
                            <div className="contact__content--right" >
                                <p className="contact__content--title" >Liên hệ với chúng tôi</p>
                                <input value={dataContact.Name} onChange={changeHoten} className="contact__content--input" placeholder='Họ và tên *' />
                                {
                                    statusName === true ? <p className='content__warning' >Họ và tên không được để trống</p> : <></>
                                }
                                <input value={dataContact.Email} onChange={changeEmail} className="contact__content--input" placeholder='Email *' />
                                {
                                    statusEmail === 1 ? <p className='content__warning' >Email không được để trống</p> :
                                        statusEmail === 2 ? <p className='content__warning' >Sai định dạng email, mời nhập lại</p> : <></>
                                }
                                <input value={dataContact.Title} onChange={changeTitle} className="contact__content--input" placeholder='Tiêu đề' />
                                <textarea value={dataContact.Content} onChange={changeContent} className="contact__content--textarea" placeholder='Nội dung liên lạc *' />
                                {
                                    statusContent === true ? <p className='content__warning' >Nội dung liên lạc không được để trống</p> : <></>
                                }
                                <div onClick={submitContact} className='button button__contact '>Gửi</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: '100px 0', textAlign: 'center' }}>
                        <div className='footer__title'>BẠN VẪN ĐẮN ĐO TRONG VIỆC LỰA CHỌN SỬ DỤNG? HÃY CHỌN MỘT GÓI VÀ DÙNG THỬ NGAY BÂY GIỜ</div>
                        <div className='footer__description'>BẠN CÓ MUỐN THEO DÕI ĐỂ ĐƯỢC TIẾP CẬN THÊM CÁC THÔNG TIN NHẰM MỤC ĐÍCH NÂNG CAO TRẢI NGHIỆM DỊCH VỤ CỦA CHÚNG TÔI?</div>
                        <div style={{ margin: '50px 0 0', display: "flex", justifyContent: "center" }}>
                            <input className='input' placeholder='Email' />
                            <div className='button'>THEO DÕI</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='over over--background'>
                <div className='container footer'>
                    <img loading="lazy" src={require('../assets/img/icon.png')} alt="Logo" className='header__logo' />
                    <div className='footer__list'>
                        <div className='footer__list__item'>TRANG CHỦ</div>
                        <div className='footer__list__item'>TÍNH NĂNG</div>
                        <div className='footer__list__item'>ĐÁNH GIÁ</div>
                        <div className='footer__list__item'>LIÊN HỆ</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PageHome;
