import './style/App.css';
import Page from './view/PageHome.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

function App() {
  return (
    <Page />
  );
}

export default App;
